import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    filter: true,
    modelValue: $setup.departments,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.departments) = $event)),
    options: $setup.departmentList.map((o) => {
        return { name: o.name, value: o.id };
      }),
    optionLabel: "name",
    placeholder: "Departman Seçiniz",
    display: "chip",
    onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onChange($event)))
  }, null, 8, ["modelValue", "options"]))
}